<template>
    <div id="explore" style="background: none !important;">
        <v-container fluid class="pt-0 pb-0 fill-height">
            <!-- 菜单 -->
            <div class="top-warp">
                <v-row class="mt-5 text-center nav" no-gutters>
                    <v-col class="ml-3">
                        <div
                            class="navitem"
                            :style="{background:$vuetify.theme.dark?'#000':'#fff'}"
                            :class="{'active':tabType==0}"
                            @click="changeTab(0)"
                        >
                            <img
                                :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                                :src=" require(`@/assets/icon_slices/fx_${tabType == 0?2:1}.png`)"
                                alt
                            />
                            <div class="ml-1">发现</div>
                        </div>
                    </v-col>
                    <v-col class="ml-7">
                        <div
                            class="navitem"
                            :style="{background:$vuetify.theme.dark?'#000':'#fff'}"
                            :class="{'active':tabType==1}"
                            @click="changeTab(1)"
                        >
                            <img
                                :style="{filter:$vuetify.theme.dark?'invert(1)':'invert(0)'}"
                                :src=" require(`@/assets/icon_slices/dy_${tabType == 1?2:1}.png`)"
                                alt
                            />
                            <div class="ml-1">订阅</div>
                        </div>
                    </v-col>
                    <v-col class="ml-3">
                        <div
                            class="navitem"
                            :style="{background:$vuetify.theme.dark?'#000':'#fff'}"
                            :class="{'active':tabType==2}"
                            @click="fixtab()"
                        >
                            <img
                                :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                                :src=" require(`@/assets/icon_slices/dz_${tabType == 2?2:1}.png`)"
                                alt
                            />

                            <div
                                class="ml-1"
                                v-if="vuex_user.address"
                            >{{vuex_user.address.split('-')[2]}}</div>
                            <div class="ml-1" v-if="!vuex_user.address">附近</div>
                        </div>
                    </v-col>

                    <v-col class="ml-3 mr-7">
                        <div
                            class="navitem"
                            :style="{background:$vuetify.theme.dark?'#000':'#fff'}"
                            :class="{'active':tabType==3}"
                            @click="changeTab(3)"
                        >
                            <img
                                :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                                :src=" require(`@/assets/icon_slices/rm_${tabType == 3?2:1}.png`)"
                                alt
                            />
                            <div class="ml-1">最热</div>
                        </div>
                    </v-col>
                    <v-col class="ml-3">
                        <div
                            class="navitem"
                            :style="{background:$vuetify.theme.dark?'#000':'#fff'}"
                            :class="{'active':tabType==4}"
                            @click="changeTab(4)"
                        >
                            <img
                                :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                                :src=" require(`@/assets/icon_slices/sj_${tabType == 4?2:1}.png`)"
                                alt
                            />
                            <div class="ml-1">最新</div>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <v-chip-group
                :active-class="$vuetify.theme.dark?'checkchipdark':'checkchip'"
                mandatory
                class="pl-12 pr-12 mt-5"
                :style="{background:$vuetify.theme.dark?'#000000':'#ffffff'}"
                :center-active="true"
                style="width: 100%;"
            >
                <!-- :dark="$vuetify.theme.dark" -->
                <v-chip
                    :style="{color:$vuetify.theme.dark?'#000000':'#ffffff'}"
                    @click="search(i)"
                    v-for="i in typeArr"
                    :key="i.key"
                >
                    {{
                    i.txt
                    }}
                </v-chip>
            </v-chip-group>

            <!-- 发现 -->
            <mescroll-vue
                ref="mescroll0"
                v-show="tabType==0"
                :up="getMescrollUp(0)"
                @init="mescrollInit0"
            >
                <v-row
                    id="dataList0"
                    :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                >
                    <!-- <v-col
                            :key="pd.id"
                            class="mt-8 data-title"
                            v-if="idx == 0 || idx ==4"
                            cols="12"
                    >分类标题-{{idx}} {{pd.pdName}}</v-col>-->
                    <v-col
                        v-for="(pd,idx) in tab0.list"
                        style="width:200px;overflow: hidden;text-align:center"
                        :style="{'margin-left':idx%6!=0?'20px':'0'}"
                        cols="2"
                        class="mt-7"
                        :key="pd.id"
                    >
                        <v-avatar
                            :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                            v-if="pd.headimg"
                            size="86"
                            @click.stop="toHome(pd.id)"
                        >
                            <v-img
                                class="cursor"
                                :src="vuex_baseUrl + pd.headimg"
                                :lazy-src="vuex_base64Avatar"
                            ></v-img>
                        </v-avatar>
                        <v-avatar
                            :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                            class="white--text headline cursor"
                            v-else
                            size="86"
                            color="red"
                            @click.stop="toHome(pd.id)"
                        >{{pd.nickname.split('')[0]}}</v-avatar>
                        <div
                            :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                            class="mt-6 font-weight-bold text-no-wrap"
                        >{{pd.nickname}}</div>
                        <div
                            :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                            class="mt-2"
                        >
                            {{pd.fans}}粉丝
                            <span class="ml-1">{{pd.num}}视频</span>
                        </div>
                        <div
                            :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                            class="justify-center d-flex align-center"
                        >
                            <v-btn
                                class="mt-5 pl-3 pr-3 rounded-0"
                                style="font-size:14px"
                                outlined
                                color
                                @click="subscribe(pd.id,idx)"
                            >
                                <template v-if="pd.follow == 1">
                                    <v-icon size="14">mdi-plus</v-icon>
                                    <span>未订阅</span>
                                </template>
                                <template v-if="pd.follow == 2">
                                    <v-icon size="14">mdi-check</v-icon>
                                    <span>已订阅</span>
                                </template>
                                <template v-if="pd.follow == 3">
                                    <v-icon size="14">mdi-check</v-icon>
                                    <span>相互订阅</span>
                                </template>
                            </v-btn>
                        </div>
                    </v-col>
                    <!-- <v-col v-if="idx" :key="idx">{{pd}}</v-col> -->
                </v-row>
            </mescroll-vue>

            <!--订阅-->
            <mescroll-vue
                ref="mescroll1"
                class="pl-12 pr-12"
                v-show="tabType==1"
                :down="getMescrollDown(1)"
                :up="getMescrollUp(1)"
                @init="mescrollInit1"
            >
                <v-row
                    id="dataList1"
                    :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                >
                    <v-col v-if="tab1.list.length == 0" cols="12"></v-col>
                    <v-col
                        v-for="(item) in tab1.list"
                        :key="item.id"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        class="mx-xs-auto"
                    >
                        <video-card
                            :card="{ maxWidth: 350 }"
                            :video="item"
                            :channel="item"
                            :collectionLists="collectionLists"
                            @refresh="refresh"
                            @showMsg="showMsg"
                            @getcollectionList="getcollectionList"
                            @showCollect="showCollect"
                            @tofeedback="tofeedback"
                        ></video-card>
                    </v-col>
                </v-row>
            </mescroll-vue>

            <!-- 附近 可不配down-->
            <mescroll-vue
                ref="mescroll2"
                v-show="tabType==2"
                :up="getMescrollUp(2)"
                @init="mescrollInit2"
            >
                <v-row
                    id="dataList2"
                    :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                >
                    <v-col v-if="tab2.list.length == 0" cols="12"></v-col>
                    <v-col
                        v-for="(item) in tab2.list"
                        :key="item.id"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        class="mx-xs-auto"
                    >
                        <video-card
                            :card="{ maxWidth: 350 }"
                            :video="item"
                            :channel="item"
                            :collectionLists="collectionLists"
                            @refresh="refresh"
                            @showMsg="showMsg"
                            @getcollectionList="getcollectionList"
                            @showCollect="showCollect"
                            @tofeedback="tofeedback"
                        ></video-card>
                    </v-col>
                </v-row>
            </mescroll-vue>
            <!-- 最热 -->
            <mescroll-vue
                ref="mescroll3"
                v-show="tabType==3"
                :up="getMescrollUp(3)"
                @init="mescrollInit3"
            >
                <v-row
                    id="dataList3"
                    :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                >
                    <v-col v-if="tab3.list.length == 0" cols="12"></v-col>
                    <v-col
                        v-for="(item) in tab3.list"
                        :key="item.id"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        class="mx-xs-auto"
                    >
                        <!-- <v-skeleton-loader
                                type="card-avatar"
                                :loading="
                                loading &&
                                    parseInt(idx / currentpage.limit) ==
                                        currentpage.page - 1
                            "
                        >-->
                        <video-card
                            :card="{ maxWidth: 350 }"
                            :video="item"
                            :channel="item"
                            :collectionLists="collectionLists"
                            @refresh="refresh"
                            @showMsg="showMsg"
                            @getcollectionList="getcollectionList"
                            @showCollect="showCollect"
                            @tofeedback="tofeedback"
                        ></video-card>
                        <!-- </v-skeleton-loader> -->
                    </v-col>
                </v-row>
            </mescroll-vue>

            <!-- 最新 -->
            <mescroll-vue
                ref="mescroll4"
                v-show="tabType==4"
                :up="getMescrollUp(4)"
                @init="mescrollInit4"
            >
                <v-row
                    id="dataList4"
                    :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                >
                    <v-col v-if="tab4.list.length == 0" cols="12"></v-col>
                    <v-col
                        v-for="(item) in tab4.list"
                        :key="item.id"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        class="mx-xs-auto"
                    >
                        <!-- <v-skeleton-loader
                                type="card-avatar"
                                :loading="
                                loading &&
                                    parseInt(idx / currentpage.limit) ==
                                        currentpage.page - 1
                            "
                        >-->
                        <video-card
                            :card="{ maxWidth: 350 }"
                            :video="item"
                            :channel="item"
                            :collectionLists="collectionLists"
                            @refresh="refresh"
                            @showMsg="showMsg"
                            @getcollectionList="getcollectionList"
                            @showCollect="showCollect"
                            @tofeedback="tofeedback"
                        ></video-card>
                        <!-- </v-skeleton-loader> -->
                    </v-col>
                </v-row>
            </mescroll-vue>

            <v-overlay :value="overlay">
                <v-card light style="position: relative;background: #fff;color: #000;" text>
                    <img
                        style="position: absolute;top: -40px;right: -40px;cursor: pointer;"
                        src="@/assets/icons/shut.png"
                        alt
                        @click="isAddcollection=false, overlay = false;"
                    />

                    <v-card-title style="display:flex;">
                        <div v-if=" !isAddcollection">添加到播放列表</div>
                        <div v-else class>新建收藏夹</div>
                    </v-card-title>

                    <div class="ml-3 mr-3">
                        <v-divider></v-divider>
                    </div>
                    <v-card-text v-if="!isAddcollection">
                        <div class style="max-height:240px;overflow:auto">
                            <div
                                v-for="item in collectionLists"
                                v-ripple
                                class="mt-3 interestbar"
                                :key="item.id"
                                @click="setcollection(item.id)"
                            >{{item.name}}</div>
                        </div>
                        <div v-ripple class="mt-3 interestbar" @click=" isAddcollection = true ">
                            <img class="pr-2 iconcuston" src="@/assets/icons/save.png" alt />
                            新建收藏夹
                        </div>
                    </v-card-text>
                    <v-card-text v-else>
                        <div class>
                            <v-switch
                                v-model=" switch1 "
                                :label=" switch1 == 1 ? '公开' : '私有'"
                                color="black"
                            ></v-switch>
                        </div>
                        <div class="interestbar">
                            <v-text-field v-model=" collectTxt " placeholder="收藏夹名称" solo></v-text-field>
                        </div>
                    </v-card-text>
                    <div class="ml-3 mr-3">
                        <v-divider></v-divider>
                    </div>
                    <div class="ml-3 mr-3 mt-15 pb-15">
                        <v-btn
                            v-if="!isAddcollection"
                            block
                            dark
                            style="color: #fff;"
                            @click="overlay = false; isAddcollection = false"
                        >取消</v-btn>
                        <v-btn v-else block dark style="color: #fff;" @click="toaddcollection()">确定</v-btn>
                    </div>
                </v-card>
            </v-overlay>
            <city-select
                v-if="cityoverlay"
                @closecityselect="closecityselect"
                @cityChange="cityChange"
                @input="cityoverlay=false"
            ></city-select>

            <v-overlay :value="feedback">
                <v-card
                    v-if="feedback"
                    v-click-outside="onClickOutside"
                    :light="$vuetify.theme.dark?false: true"
                    style="position: fixed;top:50%; left: 50%;margin-top: -190px; margin-left: -240px; z-index:10;"
                    text
                >
                    <img
                        style="position: absolute;top: -40px;right: -40px;cursor: pointer;"
                        src="@/assets/icons/shut.png"
                        alt
                        @click=" feedback = false;"
                    />

                    <v-card-title style="display:flex;">
                        <div>反馈事项</div>
                    </v-card-title>

                    <div class="ml-3 mr-3">
                        <v-divider></v-divider>
                    </div>
                    <v-card-text>
                        <div class style="max-height:240px;overflow-y: auto;">
                            <v-radio-group v-model="feedValue">
                                <v-radio
                                    class="ml-2 interestbar"
                                    color="#000"
                                    v-for="item in vuex_reportLists"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></v-radio>
                            </v-radio-group>
                        </div>
                    </v-card-text>

                    <div class="ml-3 mr-3">
                        <v-divider></v-divider>
                    </div>
                    <div class="ml-3 mr-3 mt-15 pb-15">
                        <v-btn block dark style="color: #fff;" @click="setreport()">确定</v-btn>
                    </div>
                </v-card>
            </v-overlay>
            <v-snackbar v-model="isShow" centered :width="'100px'" :timeout="2000">{{ showToast }}</v-snackbar>
            <!-- <v-snackbar v-model="isShow" centered :width="'200px'" :timeout="2000">{{ showToast }}</v-snackbar> -->
        </v-container>
    </div>
</template>

<script>
import videoCard from "@/components/VideoCard";
import AMapLoader from "@amap/amap-jsapi-loader";
import moment from "moment";
moment.locale("zh-cn");
// 引入mescroll的vue组件
import MescrollVue from "mescroll.js/mescroll.vue";
import { mapGetters } from "vuex";
// 模拟数据
// import mockData from "@/mock/pdlist";
import CitySelect from "@/components/CitySelect.vue";

export default {
    name: "Home",
    components: {
        MescrollVue,
        videoCard,
        CitySelect,
    },
    data() {
        return {
            feedback: false, //举报弹框
            feedValue: null,

            isShow: false, //提示框
            showToast: null,
            typeArr: [],
            tab0: { mescroll: null, list: [], isListInit: false }, //  发现
            tab1: { mescroll: null, list: [], isListInit: false }, // 订阅
            tab2: { mescroll: null, list: [], isListInit: false }, // 附近
            tab3: { mescroll: null, list: [], isListInit: false }, //  最热
            tab4: { mescroll: null, list: [], isListInit: false }, // 最新
            tabType: 0, // 菜单类型

            collectionLists: [], //收藏夹
            isAddcollection: false, //是否新增收藏夹
            overlay: false, //收藏弹框
            collectTxt: null,
            switch1: 1, //公开 私有
            routerAlive: false,
            isShow: false, //提示框
            showToast: null,
            currentId: null, //当前弹框id
            labelid: null, //检索条件标签id
            cityoverlay: false, //城市选择弹框
        };
    },
    watch: {
        // vuex_mode(val) {
        //     this.changeTab(this.tabType);
        // },
        $route(val) {
            setTimeout(() => {
                let parmes = val.query.type;
                console.log("parmesparmes", parmes);
                if (parmes > -1 && parmes < 5) {
                    this.changeTab(Number(parmes));
                }
            }, 500);
        },
        vuex_mode(val){
              this.changeTab(this.tabType);
            console.log('valvalval',val);
        }
    },

    mounted() {
        let parmes = this.$router.history.current.query.type;
        if (parmes > -1 && parmes < 5) {
            setTimeout(() => {
                console.log("parmesparmesparmesparmesparmesparmes", parmes);
                this.changeTab(Number(parmes));
            }, 30);
        }else{
                this.changeTab(0);
        }
        this.initialLabel();
        if (this.collectionLists.length < 1) {
            this.getcollectionList();
        }
    },
    computed: {
        ...mapGetters(["getType"]),
    },
    methods: {
        initMap() {
            let that = this;
            window._AMapSecurityConfig = {
                securityJsCode: "56e9f4ed7704796ae903b949898a943c",
            };
            AMapLoader.load({
                key: "cded3023dce189ea5ce22886b8a59dce", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ["AMap.Geolocation", "AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            })
                .then((AMap) => {
                    AMap.plugin("AMap.Geolocation", function () {
                        var geolocation = new AMap.Geolocation({
                            // 是否使用高精度定位，默认：true
                            enableHighAccuracy: true,
                            // 设置定位超时时间，默认：无穷大
                            timeout: 10000,
                            // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                            buttonOffset: new AMap.Pixel(10, 20),
                            //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                            zoomToAccuracy: true,
                            //  定位按钮的排放位置,  RB表示右下
                            buttonPosition: "RB",
                            extensions: "base",
                        });

                        geolocation.getCurrentPosition(function (
                            status,
                            result
                        ) {
                            if (status == "complete") {
                                onComplete(result);
                            } else {
                                onError(result);
                            }
                        });

                        function onComplete(data) {
                            var lnglat = [data.position.lng, data.position.lat];
                            AMap.plugin("AMap.Geocoder", function () {
                                var geocoder = new AMap.Geocoder({
                                    // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                                    city: "010",
                                });
                                geocoder.getAddress(
                                    lnglat,
                                    function (status, result) {
                                        if (
                                            status === "complete" &&
                                            result.info === "OK"
                                        ) {
                                            let data =
                                                result.regeocode
                                                    .addressComponent;
                                            that.cityChanges(
                                                data.province,
                                                data.city,
                                                data.district
                                            );
                                            // result为对应的地理位置详细信息
                                        } else {
                                            this.cityoverlay = true;
                                        }
                                    }
                                );
                            });
                            // data是具体的定位信息
                        }

                        function onError(data) {
                            this.cityoverlay = true;

                            console.log("datadataerr", data);
                            // 定位出错
                        }
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        setreport() {
            let url = "/api/Operation/report";
            this.$ajax
                .post(url, {
                    sid: this.feedback,
                    rid: this.feedValue,
                    type: this.getType,
                })
                .then((res) => {
                    this.showMsg(res.msg);
                    this.feedback = null;
                });
        },
        tofeedback(resourcesId) {
            this.feedback = resourcesId;
        },
        showMsg(msg) {
            this.showToast = msg;
            this.isShow = true;
        },
        toHome(id) {
            this.$router.push("/channels/" + id);
        },
        cityChange(e) {
            let url = "/api/Basics/information";
            let input =
                e.province.label + "-" + e.city.label + "-" + e.area.label;
            this.$ajax
                .post(url, {
                    address: input,
                })
                .then((res) => {
                    this.showToast = res.msg;
                    this.isShow = true;
                    if (res.code == 1) {
                        this.vuex("vuex_user.address", input);
                        this.changeTab(this.tabType);
                    }

                    // this.getList();
                });
        },
        cityChanges(province, city, area) {
            let url = "/api/Basics/information";
            let input = province + "-" + city + "-" + area;
            this.$ajax
                .post(url, {
                    address: input,
                })
                .then((res) => {
                    // this.showToast = res.msg;
                    // this.isShow = true;
                    if (res.code == 1) {
                        this.vuex("vuex_user.address", input);
                        this.changeTab(this.tabType);
                    }

                    // this.getList();
                });
        },
        search(i) {
            console.log("iiiii", i);
            this.labelid = i.key;
            this.changeTab(this.tabType);
        },
        closecityselect() {
            console.log("15918919854");
            this.cityoverlay = false;
        },
        subscribe(userId, idx) {
            //订阅||取消订阅
            let url = "/api/Explore/subscribe";
            this.$ajax
                .post(url, {
                    followid: userId,
                })
                .then((res) => {
                    this.showToast = res.msg;
                    this.isShow = true;
                    if (res.code == 1) {
                        this.tab2.list[idx].follow = res.status;
                    }
                    // this.getList();
                });
        },
        setcollection(collectionId, resourcesId = this.currentId) {
            let url = "/api/Operation/favorite";

            this.$ajax
                .post(url, {
                    fid: collectionId,
                    rid: resourcesId,
                    type: this.getType,
                })
                .then((res) => {
                    this.showToast = res.msg;
                    this.isShow = true;
                });
        },
        showCollect(videoId) {
            this.currentId = videoId;
            this.overlay = true;
        },
        getcollectionList() {
            let url = "/api/Operation/getcollection";
            this.$ajax.post(url, {}).then((res) => {
                this.collectionLists = res.folder;
            });
        },
        toaddcollection() {
            let url = "/api/Operation/collection";
            this.$ajax
                .post(url, {
                    name: this.collectTxt,
                    privacy: this.switch1,
                })
                .then((res) => {
                    this.isAddcollection = false;
                    if (res.code == "1") {
                        this.getcollectionList();
                        this.showToast = res.msg;
                        this.isShow = true;
                    }
                });
        },
        refresh() {
            this.changeTab(this.tabType);
        },
        initialLabel() {
            //获取标签
            let url = "/api/Index/getlabel";
            this.$ajax.post(url, {}).then((res) => {
                if (res.code == 1) {
                    let arr = res.data;
                    let typeArr = [
                        {
                            txt: "全部",
                            key: null,
                        },
                    ];

                    for (let i in arr) {
                        typeArr.push({
                            txt: arr[i].lname,
                            key: arr[i].id,
                        });
                    }

                    typeArr.sort(function (a, b) {
                        return a.key - b.key;
                    });
                    this.typeArr = typeArr;
                }
            });
        },
        // mescroll组件初始化的回调,可获取到mescroll对象
        mescrollInit0(mescroll) {
            mescroll.tabType = 0; // 加入标记,便于在回调中取到对应的list
            this.tab0.mescroll = mescroll;
        },
        mescrollInit1(mescroll) {
            mescroll.tabType = 1;
            this.tab1.mescroll = mescroll;
        },
        mescrollInit2(mescroll) {
            mescroll.tabType = 2;
            this.tab2.mescroll = mescroll;
        },
        mescrollInit3(mescroll) {
            mescroll.tabType = 3;
            this.tab3.mescroll = mescroll;
        },
        mescrollInit4(mescroll) {
            mescroll.tabType = 4;
            this.tab4.mescroll = mescroll;
        },
        // 多mescroll的配置,需通过方法获取,保证每个配置是单例
        getMescrollDown(tabType) {
            let isAuto = tabType === 0; // 第一个mescroll传入true,列表自动加载
            return {
                auto: isAuto,
                callback: this.downCallback,
            };
        },
        getMescrollUp(tabType) {
            let emptyWarpId = "dataList" + tabType;
            return {
                auto: false,
                callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page) { upCallback(page); }
                noMoreSize: 4, // 如果列表已无数据,可设置列表的总数量要大于半页才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看; 默认5
                empty: {
                    warpId: emptyWarpId, // 父布局的id;
                    icon: "http://www.mescroll.com/img/mescroll-empty.png", // 图标,默认null
                    tip: "暂无相关数据~", // 提示
                    // btntext: "去逛逛 >", // 按钮,默认""
                    // btnClick: function () {
                    //     // 点击按钮的回调,默认null
                    //     alert("点击了按钮,具体逻辑自行实现");
                    // },
                },
                toTop: {
                    // 配置回到顶部按钮
                    src: "http://www.mescroll.com/img/mescroll-totop.png", // 图片路径,默认null (建议写成网络图,不必考虑相对路径)
                },
            };
        },
        /* 下拉刷新的回调 */
        downCallback(mescroll) {
            console.log("mescrollmescrollmescrollssssssss");
            console.log("mescrollmescrollmescroll", mescroll);
            // 这里加载你想下拉刷新的数据, 比如刷新tab1的轮播数据
            if (mescroll.tabType === 0) {
                // loadSwiper();
            } else if (mescroll.tabType === 1) {
                // ....
            } else if (mescroll.tabType === 2) {
                // ....
            } else if (mescroll.tabType === 3) {
                // ....
            }
            mescroll.resetUpScroll(); // 触发下拉刷新的回调,加载第一页的数据
        },
        /* 上拉加载的回调 page = {num:1, size:10}; num:当前页 从1开始, size:每页数据条数 */
        upCallback(page, mescroll) {
            page.size = 12;

            console.log("mescroll.tabTypemescroll.tabType", mescroll.tabType);
            if (mescroll.tabType === 0) {
                // 全部
                this.tab0.isListInit = true; // 标记列表已初始化,保证列表只初始化一次
                this.getListDataFromNet(
                    mescroll.tabType,
                    page.num,
                    page.size,
                    (curPageData) => {
                        console.log("curPageDatacurPageData", curPageData);

                        mescroll.endSuccess(
                            curPageData.length ? curPageData.length : 0
                        ); // 联网成功的回调,隐藏下拉刷新和上拉加载的状态;
                        if (page.num === 1) this.tab0.list = []; // 如果是第一页需手动制空列表
                        this.tab0.list = this.tab0.list.concat(curPageData); // 追加新数据
                    },
                    () => {
                        if (page.num === 1) this.tab0.isListInit = false;
                        mescroll.endErr(); // 联网失败的回调,隐藏下拉刷新的状态
                    }
                );
            } else if (mescroll.tabType === 1) {
                // 附近
                if (!this.vuex_user.address) {
                    this.initMap();

                    return;
                }
                // this.tab1.isListInit = true; // 标记列表已初始化,保证列表只初始化一次
                this.getListDataFromNet(
                    mescroll.tabType,
                    page.num,
                    page.size,
                    (curPageData) => {
                        mescroll.endSuccess(
                            curPageData.length ? curPageData.length : 0
                        ); // 联网成功的回调,隐藏下拉刷新和上拉加载的状态;
                        if (page.num === 1) this.tab1.list = []; // 如果是第一页需手动制空列表
                        this.tab1.list = this.tab1.list.concat(curPageData); // 追加新数据
                    },
                    () => {
                        if (page.num === 1) this.tab1.isListInit = false;
                        mescroll.endErr(); // 联网失败的回调,隐藏下拉刷新的状态
                    }
                );
            } else if (mescroll.tabType === 2) {
                // 面膜
                // this.tab2.isListInit = true; // 标记列表已初始化,保证列表只初始化一次
                this.getListDataFromNet(
                    mescroll.tabType,
                    page.num,
                    page.size,
                    (curPageData) => {
                        mescroll.endSuccess(
                            curPageData.length ? curPageData.length : 0
                        ); // 联网成功的回调,隐藏下拉刷新和上拉加载的状态;
                        if (page.num === 1) this.tab2.list = []; // 如果是第一页需手动制空列表
                        this.tab2.list = this.tab2.list.concat(curPageData); // 追加新数据
                    },
                    () => {
                        if (page.num === 1) this.tab2.isListInit = false;
                        mescroll.endErr(); // 联网失败的回调,隐藏下拉刷新的状态
                    }
                );
            } else if (mescroll.tabType === 3) {
                // 图书
                // this.tab3.isListInit = true; // 标记列表已初始化,保证列表只初始化一次
                this.getListDataFromNet(
                    mescroll.tabType,
                    page.num,
                    page.size,
                    (curPageData) => {
                        mescroll.endSuccess(
                            curPageData.length ? curPageData.length : 0
                        ); // 联网成功的回调,隐藏下拉刷新和上拉加载的状态;
                        if (page.num === 1) this.tab3.list = []; // 如果是第一页需手动制空列表
                        this.tab3.list = this.tab3.list.concat(curPageData); // 追加新数据
                    },
                    () => {
                        if (page.num === 1) this.tab3.isListInit = false;
                        mescroll.endErr(); // 联网失败的回调,隐藏下拉刷新的状态
                    }
                );
            } else if (mescroll.tabType === 4) {
                // this.tab4.isListInit = true; // 标记列表已初始化,保证列表只初始化一次

                this.getListDataFromNet(
                    mescroll.tabType,
                    page.num,
                    page.size,
                    (curPageData) => {
                        mescroll.endSuccess(
                            curPageData.length ? curPageData.length : 0
                        ); // 联网成功的回调,隐藏下拉刷新和上拉加载的状态;
                        if (page.num === 1) this.tab4.list = []; // 如果是第一页需手动制空列表
                        this.tab4.list = this.tab4.list.concat(curPageData); // 追加新数据
                    },
                    () => {
                        if (page.num === 1) this.tab4.isListInit = false;
                        mescroll.endErr(); // 联网失败的回调,隐藏下拉刷新的状态
                    }
                );
            }
        },
        fixtab() {
            if (this.tabType == 2) {
                this.cityoverlay = true;
            }
            this.changeTab(2);
        },
        // 切换菜单
        changeTab(type) {
            let curTab = this.getTabData(this.tabType); // 当前列表

            let newTab = this.getTabData(type); // 新转换的列表
            if (curTab) {
                curTab.mescroll.hideTopBtn(); // 隐藏当前列表的回到顶部按钮
            }
            this.tabType = type; // 切换菜单
            if (!newTab.isListInit) {
                newTab.mescroll.triggerDownScroll(); // 加载列表
            } else {
                setTimeout(() => {
                    // 检查新转换的列表是否需要显示回到到顶按钮
                    var curScrollTop = newTab.mescroll.getScrollTop();
                    if (curScrollTop >= newTab.mescroll.optUp.toTop.offset) {
                        newTab.mescroll.showTopBtn();
                    } else {
                        newTab.mescroll.hideTopBtn();
                    }
                }, 30);
            }
        },
        // 获取菜单对应的数据
        getTabData(tabType) {
            console.log("tabTypetabTypetabType", tabType);
            if (tabType == null) tabType = this.tabType;
            if (tabType === 0) {
                return this.tab0;
            } else if (tabType === 1) {
                return this.tab1;
            } else if (tabType === 2) {
                return this.tab2;
            } else if (tabType === 3) {
                return this.tab3;
            } else if (tabType === 4) {
                return this.tab4;
            }
        },
        /* 联网加载列表数据
          在您的实际项目中,请参考官方写法: http://www.mescroll.com/api.html#tagUpCallback
          请忽略getListDataFromNet的逻辑,这里仅仅是在本地模拟分页数据,本地演示用
          实际项目以您服务器接口返回的数据为准,无需本地处理分页.
          * */
        getListDataFromNet(
            tabType,
            pageNum,
            pageSize,
            successCallback,
            errorCallback
        ) {
            // 延时一秒,模拟联网
            let url = [
                "/api/Explore/findinterest",
                "/api/Explore/subscribelist",
                "/api/Explore/nearby",
                "/api/Explore/popular",
                "/api/Explore/newest",
            ];

            let data = {
                page: pageNum,
                limit: pageSize,
                labelid: this.labelid,
            };
            // if (tabType == 0) {
            data = { ...data, type: this.getType };
            // }
            this.$ajax.post(url[tabType], data).then((res) => {
                if (res.code == 1) {
                    let arr = [];

                    if (tabType != 0) {
                        arr = res.detail;
                    } else {
                        arr = res.data;
                    }
                    console.log("arrarrarr", res);
                    successCallback(arr);
                } else {
                    errorCallback && errorCallback();
                }
            });
            // try {
            //     var listData = [];
            //     // tabType 全部商品0; 奶粉1; 面膜2; 图书3;
            //     if (tabType === 0) {
            //         // 全部商品 (模拟分页数据)
            //         for (
            //             var i = (pageNum - 1) * pageSize;
            //             i < pageNum * pageSize;
            //             i++
            //         ) {
            //             if (i === mockData.length) break;
            //             listData.push(mockData[i]);
            //         }
            //     } else if (tabType === 1) {
            //         // 奶粉
            //         for (var n = 0; n < mockData.length; n++) {
            //             if (mockData[n].pdName.indexOf("奶粉") !== -1) {
            //                 listData.push(mockData[n]);
            //             }
            //         }
            //     } else if (tabType === 2) {
            //         // 面膜
            //         for (var j = 0; j < mockData.length; j++) {
            //             if (mockData[j].pdName.indexOf("面膜") !== -1) {
            //                 listData.push(mockData[j]);
            //             }
            //         }
            //     } else if (tabType === 2) {
            //         // 图书
            //         for (var k = 0; k < mockData.length; k++) {
            //             if (mockData[k].pdName.indexOf("图书") !== -1) {
            //                 listData.push(mockData[k]);
            //             }
            //         }
            //     }
            //     // 回调
            //     successCallback && successCallback(listData);
            // } catch (e) {
            //     // 联网失败的回调
            //     errorCallback && errorCallback();
            // }
        },
    },
    beforeRouteEnter(to, from, next) {
        // 如果没有配置回到顶部按钮或isBounce,则beforeRouteEnter不用写
        next((vm) => {
            let curMescroll = vm.$refs["mescroll" + vm.tabType]; // 找到当前mescroll的ref,调用子组件mescroll-vue的beforeRouteEnter方法
            curMescroll && curMescroll.beforeRouteEnter(); // 进入路由时,滚动到原来的列表位置,恢复回到顶部按钮和isBounce的配置
        });
    },
    beforeRouteLeave(to, from, next) {
        // 如果没有配置回到顶部按钮或isBounce,则beforeRouteLeave不用写
        let curMescroll = this.$refs["mescroll" + this.tabType]; // 找到当前mescroll的ref,调用子组件mescroll-vue的beforeRouteEnter方法
        curMescroll && curMescroll.beforeRouteLeave(); // 退出路由时,记录列表滚动的位置,隐藏回到顶部按钮和isBounce的配置
        next();
    },
};
</script>

<style scoped>
.checkchip {
    background: #848484 !important;
    color: #fafafa !important;
}
.checkchipdark {
    background: #000000 !important;
    color: #ffffff !important;
}
/*以fixed的方式固定mescroll的高度*/
.mescroll {
    position: absolute;
    top: 160px;
    bottom: 0;
    height: auto;
}

.top-warp {
    width: 100%;
}
.active {
    font-weight: bold;
}
.navitem {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navitem img {
    width: 24px;
    height: 24px;
}
/* .top-warp .head {
    width: 100%;
    height: 44px;
    line-height: 44px;
    text-align: center;
}
.top-warp .head .btn-left {
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px;
    line-height: 22px;
}
.top-warp .tip {
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    text-align: center;
}
.top-warp .nav {
    text-align: center;
    height: 40px; 
    background: white;
}
.top-warp .nav > div {
    display: inline-block;
    width: 22%;
    line-height: 36px;
    font-size: 14px;
}
.top-warp .nav .active {
    border-bottom: 1px solid #ff6990;
    color: #ff6990;
} */

/*展示上拉加载的数据列表*/
.data-li {
    position: relative;
    height: 80px;
    padding: 10px 8px 10px 120px;
    border-bottom: 1px solid #eee;
}
.data-title {
    font-weight: bold;
}
.data-li .pd-img {
    position: absolute;
    left: 18px;
    top: 10px;
    width: 80px;
    height: 80px;
}
.data-li .pd-name {
    font-size: 13px;
    line-height: 20px;
    height: 40px;
    margin-bottom: 10px;
    overflow: hidden;
}
.data-li .pd-price {
    font-size: 13px;
    color: red;
}
.data-li .pd-sold {
    font-size: 12px;
    margin-top: 8px;
    color: gray;
}
</style>
